#Slider-Home {
	.slider-text {
		display: block;
		position: absolute;
		top: 50%;
		transform: translatey(-50%);
		h3,em {
			font-style: normal;
			color: $white;
			font-family: $font-open;
			font-size: 18px;
			line-height: 15px;
			font-weight: 400;
			margin: 0;
			text-shadow: $gray-light 0.075rem 0.075rem 0.075rem;
		}

		span {
			display: block;
			color: $white;
			font-family: $font-open;
			font-size: 13px;
			line-height: 15px;
			font-weight: 300;
			color: $white;
			opacity: 0.9;
			text-shadow: $gray-light 0.075rem 0.075rem 0.075rem;
		}
		@media (min-width: 768px) {
			h3,em {
				font-size: 42px;
				line-height: 50px;
			}
			span {
				font-size: 30px;
				line-height: 50px;
				letter-spacing: 3px;
			}
		}

		&.p-center {
			left: 50%;
			transform: translate(-50%,-50%);
			text-align: center;
		}

		&.p-right {
			text-align: center;
			left: 50%;
		}

		&.p-left {
			left: 10%;
			text-align: center;
		}
	}
	.slick-slide img {
		width: 100%;
	}

	.slick-slide {
		.banner-link {
			width: 100%;
			img {
				width: 100%;
			}
		}
	}

	button.slick-arrow {
		color: $white;
		z-index: 1000;
		height: 90px;
		font-weight: 300;
		width: 90px;
		@media (max-width: 768px) {
			display: none !important;
		}
	}

	button.slick-prev, button.slick-next {
		&:before {
			content: '';
			display: block;
			width: 50px;
			height: 50px;
			-webkit-transform: rotate(45deg);
			-moz-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			-o-transform: rotate(45deg);
			transform:  rotate(45deg);
		}
	}

	.slick-prev {
        left: 55px;
    	&:before {
			margin-left: 30px;
			border-left: 2px solid white;
			border-bottom: 2px solid white;
    	}
	}
	.slick-next {
        right: 55px;
    	&:before {
			margin-right: 30px;
			border-top: 2px solid white;
			border-right: 2px solid white;
    	}
	}
}
.bubble {
	transform: scale(0);
	border-radius: 50%;
	z-index: -1;
	position: absolute;
	top: -70px;
	width: 100%;
	height: 100%;
	transition: all 0.7s;
}
.bubble.prev {
	left: 16px;
}
.bubble.next {
	right: 19px;
}
.bubble.expand {
	transform: scale(1);
}
.ctBubble {
	display: block;
	position: relative;
	width: 90px;
	height: 90px;
}