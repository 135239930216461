.parallax {
	height: 420px;
	background: $white;
	position: relative;
	background-repeat: no-repeat;
	background-position: center bottom;
	background-size: cover;
	background-attachment: fixed;
}

.port01 {
	background-image: url(/images/port01-weddings.jpg);
}

.port02 {
	background-image: url(/images/port02-engagement.jpg);
}

.port03 {
	background-image: url(/images/port03-urban.jpg);
}

.port04 {
	background-image: url(/images/port04-maternity.jpg);
}

.port05 {
	background-image: url(/images/port05-family.jpg);
}

.port06 {
	background-image: url(/images/port06-landscape.jpg);
}

.port07 {
	background-image: url(/images/port07-fashion.jpg);
}