footer {
	background-color: $white;
	padding: 2rem 0 4rem 0;
	margin-top: 2rem;
	border-top: 2px solid black;
	.copyright {
		&.center {
			p {
				text-align: center;
			}
		}
		p {
			font-weight: 400;
			font-size: 0.6875rem;
			margin: 0;
			line-height: 1.8;
		}
		a.sitemap {
			font-size: 13px;
			text-transform: none;
		}
	}
	.social-container {
		text-align: center;
		ul {
			margin: 2rem 0 0 0;
			padding: 0;
			@media (min-width: 767px) {
				margin-top: 0;
			}
			list-style: none;
			li {
				display: inline-block;
				margin-left: 20px;
				a {
					font-size: 1.2rem;
				}
			}
		}
	}
}
