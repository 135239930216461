/*
|--------------------------------------------------------------------------
| Aligns
|--------------------------------------------------------------------------
*/
.center {
	text-align: center;
}

.left {
	text-align: left;
}

.right {
	text-align: right;
}

.relative {
	position: relative;
}

/*
|--------------------------------------------------------------------------
| Separators
|--------------------------------------------------------------------------
*/
.sm-separator {
	background-color: transparent;
	margin-top: 1.4rem;
	border: none;
	width: 100%;
	position: relative;
}
.separator {
	background-color: transparent;
	margin-top: 1.875rem;
	border: none;
	width: 100%;
	position: relative;
}

.title-break {
	width: 40px;
	border-color: $black-light;
    border-top: 2px solid $black-light;
}

.inner-line {
	position: relative;
	display: block;
	margin: 10px 0 10px 0;
	height: 1px;
	background-color: $black-light; 
	width: 100%;
}
 .big-separator {
 	margin: 22px 0;
 	height: 1px;
 	background-color: transparent;
 	display: block;
 	border: 0px;
 	width: 100%;
 }
/*
|--------------------------------------------------------------------------
| Paddings
|--------------------------------------------------------------------------
*/

.small-padding {
	padding: 30px 0;
}

.np {
	padding: 0;
}
.pt-1 {
	padding-top: 1rem;
}

/*
|--------------------------------------------------------------------------
| Margins
|--------------------------------------------------------------------------
*/

.nm {
	margin: 0;
}

.full-width {
	width: 100%;
}

/*
|--------------------------------------------------------------------------
| Efects
|--------------------------------------------------------------------------
*/

.img-zoom {
	max-width: 100%;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: all 1s ease-out;
    -webkit-transition: all 1s ease-out;
    &:hover {
    	-ms-transform: scale(1.1);
		-moz-transform: scale(1.1);
		-o-transform: scale(1.1);
		-webkit-transform: scale(1.1);
		transform: scale(1.1);
		transition: all 4.5s ease-out;
		-webkit-transition: all 4.5s ease-out;
    }
}

/*
|--------------------------------------------------------------------------
| Tipography
|--------------------------------------------------------------------------
*/

p.subtitle {

	font-size: 11px;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 1px;
	margin-bottom: 10px;
}

.widgetTitle {
	text-align: center;
	padding: 10px;
	border: 1px solid $black-light;
	font-family: Montserrat;
	font-size: 11px;
	font-weight: 400;
	letter-spacing: 2px;
	text-transform: uppercase;
	color: $black-light;
}

.uppercase {
	text-transform: uppercase;
}

.modaal-inner-wrapper {
	background-color: rgba(26, 26, 26,0.8);
	.modaal-container {
		max-width: 600px;
		background-color: transparent;
    	box-shadow: none;
		.modaal-content-container {
			padding: 0;
			img {
				max-width: 100%;
			}
			button.custom-close {
				width: 100%;
				background: none;
				outline: none;
				border:none;
				color: white;
				text-align: center;
				margin: 30px 0;
				text-transform: uppercase;
			}
		}
		button.modaal-close {
			display: none;
		}
	}
}

.logos-home {
	max-width: 212px;
	max-height: 34px;
	@media(max-width: 900px) {
		margin: 0 0 30px 0;
	}
}