.generalForm {
	input {
		width: 100%;
		border: 1px solid $black-light;
		padding: 0.5rem;
	}

	textarea {
		width: 100%;
		padding: 0.5rem;
	}

	label {
		font-weight: 600;
		font-family: $font-montse;
		text-transform: uppercase;
	}

	button {
		transition: all 0.5s;
		&:hover {
			transition: all 0.5s;
		}
	}
}

.contactForm {
	input {
		height: 38px;
		margin-bottom: 20px;	
	}

	label {
		font-size: 0.8125rem;
	}

	button {
		background-color: $black;
		border: 2px solid $black;
		color: $white;
		padding: 7px 19px;
		font-family: $font-hind;
		font-size: 0.8125rem;
		letter-spacing: 2px;
		font-weight: 600;
		margin: 20px 0;
		&:hover {
			border: 2px solid $black-light;
			background-color: $white;
			color: $black-light;
		}
	}
}

.contractForm {
	p.title-contract {
		color: $peach;
		font-size: 1.5rem;
		font-family: $font-hind;
		font-weight: 400;
		text-shadow: 1px $black-light;
		margin-top: 2rem;
	}

	p.title {
		font-weight: 600;
	    font-family: "Montserrat", sans-serif;
	    text-transform: uppercase;
	    margin-bottom: 6px;
	}
	.info {
		margin-bottom: 16px;
	    opacity: 1;
	    border: none;
	    border-bottom: 1px solid #222222;
	    padding: 0.5rem;
	}

	label {
		font-size: 14px;
	}

	input {
		border: none;
		border-bottom: 1px solid $black-light;
	}

	input.datepicker {
		border: none;
		display: inline-block;
		width: auto;
		margin: 0 0 1rem 10px;
		border: 1px solid $black-light;
	}

	input.datetimepicker {
		border-bottom: 1px solid $black-light;
	}

	button.black-white {
		background-color: $black-light;
		color: $white;
		border: 1px solid $black-light; 
		margin: 10px;
	    padding: 16px;
	    letter-spacing: 2px;
		&:hover {
			background-color: $white;
			color: $black-light;
			border: 1px solid $black-light;
		}
	}

	
	span.input-group-addon {
		padding: 6px 12px;
		font-size: 14px;
		font-weight: normal;
		line-height: 1;
		color: #555555;
		text-align: center;
		background-color: #eeeeee;
		border: 1px solid #ccc;
	}

	canvas {
		border: 1px solid $black-light;
	}
} 