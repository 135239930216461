
/*
|--------------------------------------------------------------------------
| General Styles
|--------------------------------------------------------------------------
*/
html {
	font-size: 16px;
}
body {
	position: relative;
	font-style: normal;
	font-size: 16px;
	font-weight: 300;
	line-height: 1;
	width: 100%;
	height: 100%;
	background-color: white;
}
a {
	display: inline-block;
	color: $black-light;
	&:hover {
		text-decoration: none;
	}
}
p {
	text-align: justify;
	color: $gray-dark;
	line-height: 1.2;
}

h1, h2 {
	font-family: $font-montse;
	font-weight: 400;
    color: $black-light;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1.6;
}

h1 {
	font-size: 2.125rem;
}

h2 {
	font-size: 2rem;
}

a {
	font-family: $font-montse;
	font-size: 0.6875rem;
	font-weight: 400;
    text-transform: uppercase;
    text-align: center;
}


.gutter-sizer {
	width: 0;
}

.grid-item {
	width: 33.3333%;
	margin: 0;
	a.img-gallery {
		width: 100%;
		img {
			width: 100%;
		}
	}
}

h1.withbadge {
	margin-top: 80px;
	@media (max-width: 767px) {
		margin-top: 10px;
	}
}

.badgeclique {
	position: absolute;
    top: -77px;
    left: 50%;
    width: 150px;
    margin-left: -75px;
	img {
		max-width: 150px;
	}
	@media (max-width: 768px) {
		display: none !important;
	}
}