#Testimonials-Slide {
	div {
		img {
			width: 100%;
		}
	}

	.slick-prev, .slick-next {
		-webkit-transition: all .3s ease;
		-moz-transition: all .3s ease;
		transition: all .3s ease;
		background: rgba(0,0,0,0.2);
		border-radius: 5px;
		height: 60px;
		width: 60px;
		&:before {
			font-family: 'FontAwesome';
		}
		@media (max-width: 768px) {
	    	display: none !important;
	    }
	}

	.slick-prev {
		left: -80px;
    	&:before {
        	content: "\f104";
    	}
	}
	.slick-next {
		right: -80px;
    	&:before { 
        	content: "\f105";
    	}
	}
}
