header {
	background-color: $white;
	.preheader {
		background-color: $black-light;
		padding: 0.625rem;
		text-align: right;
		a {
			color: white;
			display: inline-block;
			letter-spacing: 1px;
			font-size: 0.875rem;
			line-height: 30px;
			font-weight: 400;
			font-family: $font-hind;
			&:hover, &:active {
				text-decoration: none;
			}
			i {
				margin: 0 10px;
				color: white;
			}
		}
		a.phone, a.mail {
			margin-left: 1rem;
			margin-right: 0.625rem;
			transition: all 0.7s;
			@media (max-width: 520px) {
				font-size: 0.6rem;
			}
			i {
				margin: 0;
			}
		}
	}
	.nav-container {
		background-color: rgba(256, 256, 256, 0.95);
		padding-top: .625rem;
		padding-bottom: .625rem;
		transition: all 0.7s;
		width: 100%;
		.logo {
			overflow: hidden;
			img.logo-white {
				display: none;
			}
			img.logo-black, img.logo-white {
				max-width: 192px;
				float: left;
			}
			@media (max-width: 768px) {
				width: 100%;
				text-align: center;
			}
		}
		nav {
			span.menu-trigger {
				display: block;
				text-align: center;
				margin: 6px auto;
				@media (min-width: 768px) {
					display: none;
				}
			}
			ul {
				list-style: none;
				margin: 0;
				padding: 0;
				text-align: center;
				display: block;
				li {
					position: relative;
					display: block;
					margin-right: 4px;
					@media (min-width: 768px) {
						display: inline-block;
					}
					a {
						font-family: $font-montse;
						font-size: 0.6875rem;
						color: $black-light;
						transition: all 0.5s;
						padding: 22px 13px;
						font-weight: 600;
    					letter-spacing: 1px;
    					text-transform: uppercase;
    					border-bottom: 0px;
					}
					&:hover,
					&.activa {
						a {
							text-decoration: none;
							border-bottom: 1px solid $black-light;
						}
					}
					a.bars {
						font-size: 1.2rem;
						border-bottom: 1px solid $white;
						i {
							margin: 0 0 0 5px;
						}
						&:hover,&.activa {
							border-bottom: 1px solid $white;
						}
					}
				}
				@media (max-width: 767px) {
					display: none;
				}
			}
		}
		@media (max-width: 767px) {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	.headercontainer {
		width: 100%;
	}

	.header-email {
		@media (max-width: 767px) {
			width: 50%;
			margin: 2px;
			padding: 2px;
		}
	}
	.header-phone {
		@media (max-width: 767px) {
			width: 47%;
			margin: 2px;
			padding: 2px;
		}
	}
}

.menu-sticky {
	position: fixed;
	z-index: 1005;
	top: 0;
	width: 100%;
	background-color: rgba(34,34,34,1) !important;
	transition: all 0.7s;
	nav {
		ul {
			li {
				a {
					color: white !important;
					transition: all 0.0001s;
					&:hover,
					&.activa {
						a {
							text-decoration: none;
							border-bottom: 1px solid white !important;
							transition: all 0.0001s;
						}
					}
				}
				a.bars {
					display: none;
				}
			}
		}
	}
	.logo {
		img.logo-black {
			display: none;
		}
		img.logo-white {
			display: block !important;
		}
	}
	@media (max-width: 768px) {
		position: relative;
	}
}
