a.black-white {
	background-color: $white;
	color: $black-light;
	border: 1px solid $black-light;
	margin: 10px;
    padding: 10px;
    letter-spacing: 2px;
	&:hover {
		background-color: $black-light;
		color: $white;
		border: 1px solid $black-light;
	}
}
.btn-view-gallery {
	color: white;
	background-color: transparent;
	border: 1px solid white;
	padding: 16px 20px 14px 20px;
	outline: none;
	cursor: pointer;
	text-transform: uppercase;
	font-size: 13px;
	font-weight: 600;
	line-height: 1.5px;
	letter-spacing: 2px;
	font-style: normal;
}