section.Home {
	.about-content {
		padding-bottom: 30px;
		img.about-person {
			display: inline-block;
			float: left;
			margin: 0 28px 14px 0;
		}
		p {
			font-family: $font-hind;
			font-size: 0.875rem;
			font-style: italic;
			font-weight: 400;
			text-align: justify;
			padding-top: 0.6125rem;
		    padding-bottom: 0.6125rem;
		    line-height: 1.6;
		}
		a.black-white {
			display: block;
		}
	}
}
section.Galleries {
	.gallery-container {
		position: relative;
		width: 100%;
		overflow: hidden;
		margin: 0;
		line-height: 0;
		text-align: center;
		a {
			max-width: 33.333%;
			width: 100%;
		    line-height: 0;
		    letter-spacing: 0;
			position: relative;
			overflow: hidden;
			display: block;
			float: left;
			@media (max-width: 767px) {
				max-width: 49%;
			}
			img.main-image {
				@extend .img-zoom;
				width: 100%;
			}
		}
	}
}
section.Blog {
	.article-container {
		text-align: center;
		a.img-link {
			width: 100%;
			height: 238px;
			overflow: hidden;
			img {
				@extend.img-zoom;
				margin-bottom: 20px;
			}
			&:hover {
				color: $black-light;
			}
		}

		a.info-cat {
			color: $peach;
			text-transform: uppercase;
		}

		h6 {
			font-family: $font-montse;
			font-size: 1.125rem;
			line-height: 1.6;
		}

		p.post-detail {
			text-align: center;
			font-size: 0.875rem;
			font-style: italic;
		}
	}
	a.all {
		font-size: 11px;
		i {
			margin-right: 5px;
			font-size: 0.875rem;
		}
		&:hover {
			color: $black-light;
			i {
				color: $gray-light;
			}
		}
	}
}
section.friends {
	background-color: rgb(239,239,239);
	overflow: hidden;
	text-align: center;
	padding: 1rem 0;
	img {
		vertical-align: baseline;
		max-width: 100%;
		display: block;
	}
	.friends-container {
		display: block;
		vertical-align: middle;
		padding: 15px;
		margin: 0 20px;
		float: left;
	}
}
section.About {
	h2.title-block {
		text-align: left;
	}
	.title-brek {
		text-align: left;
	}
	p.description {
		font-style: italic;
		line-height: 1.6;
		font-size: 0.875rem;
		text-align: left;
		font-weight: 400;
		span {
			color: $gray-exlight;
		}
	}
}
section.Portfolio {
	line-height: 0;
	a {
		position: relative;
		width: 100%;
		height: auto;
		line-height: 0;
		&:before {
			transition: all 0.3s ease;
			-webkit-transition: all 0.3s ease;
			-moz-transition: all 0.3s ease;
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			z-index: 3;
			background-image: -webkit-gradient(linear,left top,left bottom,from(transparent),color-stop(30%,transparent),color-stop(56%,rgba(0,0,0,0.1)),color-stop(83%,rgba(0,0,0,0.4)),to(rgba(0,0,0,0.59)));
			background-image: -webkit-linear-gradient(transparent 0%,transparent 50%,rgba(0,0,0,0.1) 76%,rgba(0,0,0,0.4) 93%,rgba(0,0,0,0.59) 100%);
			background-image: linear-gradient(transparent 0%,transparent 50%,rgba(0,0,0,0.1) 76%,rgba(0,0,0,0.4) 93%,rgba(0,0,0,0.59) 100%);
		}
		.parallax {
			position: relative;
			.gallery-desc, .gallery-button {
				position: absolute;
			}
			.gallery-desc {
				position: absolute;
				width: 70%;
				left: 40px;
				bottom: 25px;
				z-index: 3;
				text-align: left;
				line-height: normal;
				h4 {
					color: white;
				}
			}
			.gallery-button {
				position: absolute;
				width: 25%;
				right: 40px;
				bottom: 40px;
				z-index: 3;
				text-align: right;
			}
		}
	}
}
section.Gallery {
	.toUp {
	}
	.gallery-container {
		overflow: hidden;
		a {
			img.main-image {
				@extend .img-zoom;
				width: 100%;
			}
		}
	}
}
section.Contact {
	#MainMap {
    	height: 600px;
    	width: 100%;
    }
    a {
    	font-weight: 400;
    	&:hover {
    		color: $gray-light;
    	}
    }
    .textwidget {
     	margin-left: 50px;
    	p, a {
    		font-family: $font-hind;
    		font-size: 0.875rem;
    		font-weight: 400;
    		line-height: 1.6;
    	}
    	p.widget-title {
    		font-size: 20px;
    		text-transform: uppercase;
    		text-align: left;
    	}
    	.social-icons {
	    	width: 100%;
	    	height: auto;
	    	text-align: center;
	    	a {
	    		font-size: 1.2rem;
	    		margin: 0 0.2rem;
	    	}
	    }
    }
}
section.Videos {
	#Slider-Video {
		.slideVideo {
			height: 440px;
			background-color: #757575;
		}
		button.slick-prev {
			left: 25px;
			background-color: #555;
			z-index: 1;
			border-radius: 50%;
			width: 50px;
			height: 50px;
		}
		button.slick-next {
			right: 25px;
			background-color: #555;
			z-index: 1;
			border-radius: 50%;
			width: 50px;
			height: 50px;
		}
		button.slick-arrow:before {
			font-size: 30px;
		}
		ul.slick-dots {
			bottom: 25px;
			li {
				background-color: #999;
				border: 3px solid white;
				border-radius: 50%;
				button:before {
					visibility: hidden;
				}
			}
		}
	}
}
section.Testimonials {
	.content-testimonials {
		margin-bottom: 25px;
		a {
			display: inline-block;
			height: auto;
		}
		p {
			padding-top: 8px;
			opacity: 0.5;
			text-align: center;
			width: 100%;
			font-size: 13px;
		}
	}
}
section.case {
	.header-case {
		height: 404px;
		width: 100%;
		background-size: cover;
		background-position: center center;
		position: relative;
		.title-case {
			background: rgba(0,0,0,0.7);
			width: 100%;
			position: absolute;
			bottom: 0px;
			left: 0px;
			padding: 35px 0px 24px 0px;
			span {
				font-style: italic;
				color: white;
				font-size: 14px;
				font-weight: 400;
				margin-left: 50px;
				opacity: 0.8;
			}
		}
	}
	.go-back {
		font-family: Montserrat;
		font-size: 11px;
		font-weight: 400;
		letter-spacing: 2px;
		text-transform: uppercase;
		margin: 25px 10px 10px 10px;
		padding: 10px;
		border: 1px solid #222222;
		text-align: center;
		display: inline-block;
		-webkit-transition: background-color ease 0.5s;
		-moz-transition: background-color ease 0.5s;
		-ms-transition: background-color ease 0.5s;
		-o-transition: background-color ease 0.5s;
		transition: background-color ease 0.5s;
	}
	.go-back:hover {
		background-color: #222;
		color: white;
	}
	.content-body {
		padding-top: 75px;
		p {
			font-size: 13.5px;
			line-height: 23px;
			color: #222;
			margin-bottom: 25px;
		}
		.content-img {
			img {
				margin-bottom: 20px;
			}
		}
	}
}


section.contract {
	h2 {
		font-size: 1.5rem;
	}

	i {
		font-size: 1.5rem;
		display: inline-block;
		margin-right: 5px;
	}
}