@import "vendor/bower_components/bootstrap-sass/assets/stylesheets/bootstrap";
@import "vendor/bower_components/slick-carousel/slick/slick.scss";
@import "vendor/bower_components/slick-carousel/slick/slick-theme.scss";
@import "vendor/bower_components/modaal/dist/css/modaal.scss";


@import "elements/colors";
@import "elements/fonts";
@import "elements/general";
@import "elements/buttons";
@import "elements/utilities";
@import "elements/testimonials-slide";
@import "elements/parallax";
@import "elements/forms";
@import "elements/sections";
@import "elements/slider-home";
@import "components/header";
@import "components/footer";

/*
|--------------------------------------------------------------------------
|  Helpers
|--------------------------------------------------------------------------
*/

.mt-1 {
	margin-top: 0.25rem !important;
}

.mt-2 {
	margin-top: 0.25rem !important;
}

.mt-3 {
	margin-top: 0.5rem !important;
}

.mt-4 {
	margin-top: 1rem !important;
}

.mt-5 {
	margin-top: 1.5rem !important;
}

.mb-1 {
	margin-bottom: 0.25rem !important;
}

.mn-2 {
	margin-bottom: 0.25rem !important;
}

.mn-3 {
	margin-bottom: 0.5rem !important;
}

.mn-4 {
	margin-bottom: 1rem !important;
}

.mn-5 {
	margin-bottom: 1.5rem !important;
}
